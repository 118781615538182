import "./Image.css";
import React from "react";
import Modal from "react-modal";

export function Image(props) {
    const [isOpen, setOpen] = React.useState(false);

    const openModal = () => {
        setOpen(true);
        document.body.style.overflow = "hidden";
    };

    const closeModal = () => {
        setOpen(false);
        document.body.style.overflow = "auto";
    };

    return (
        <div
            className={`h-fit inline-flex justify-center items-center overflow-hidden cursor-pointer relative ${
                props.className ? props.className : ""
            }`}
        >
            <div className="select-none ">
                <img
                    className="relative z-10"
                    src={props.src}
                    draggable={false}
                    onClick={openModal}
                    alt=""
                />
                <img
                    className="w-full top-0 pointer-events-none absolute z-0"
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTE5NCIgaGVpZ2h0PSI4MzQiIHZpZXdCb3g9IjAgMCAxMTk0IDgzNCI+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAtUGxhY2Vob2xkZXIiPgogICAgICA8cmVjdCB3aWR0aD0iMTE5NCIgaGVpZ2h0PSI4MzQiLz4KICAgIDwvY2xpcFBhdGg+CiAgPC9kZWZzPgogIDxnIGlkPSJQbGFjZWhvbGRlciIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtUGxhY2Vob2xkZXIpIj4KICAgIDxyZWN0IHdpZHRoPSIxMTk0IiBoZWlnaHQ9IjgzNCIgZmlsbD0iI2VmZWVmMyIvPgogIDwvZz4KPC9zdmc+Cg=="
                    alt=""
                />
            </div>
            <Modal
                className="flex justify-center items-center overflow-hidden outline-none border-none h-fill"
                appElement={document.getElementById("root") || undefined}
                overlayClassName="overlay cursor-pointer"
                shouldCloseOnOverlayClick={true}
                onRequestClose={closeModal}
                isOpen={isOpen}
            >
                <img
                    loading="lazy"
                    className="select-none cursor-pointer max-w-[95vw] max-h-[95vh]"
                    src={props.src}
                    draggable={false}
                    onClick={closeModal}
                    alt=""
                />
            </Modal>
        </div>
    );
}
