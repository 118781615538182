import React from "react";
import { useTranslation } from "react-i18next";
import { NavigationLink } from "../NavigationLink/NavigationLink";

export function SubNavbar() {
    const { t } = useTranslation();

    return (
        <div className="hidden xl:flex bg-[#3A9E70] h-24 fixed mt-24 w-full whitespace-nowrap z-10 md:px-36">
            <div className="flex h-full items-center max-w-screen-xl">
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-prerequisites")}
                    target="prerequisites"
                />
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-basics")}
                    target="basics"
                />
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-drone-operations")}
                    target="drone-operations"
                />
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-forest-management-planning")}
                    target="forest-management-planning"
                />
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-action-planning")}
                    target="action-planning"
                />
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-advanced")}
                    target="advanced"
                />
            </div>
        </div>
    );
}
