import React from "react";
import { useTranslation } from "react-i18next";

export function Introduction() {
    const { t } = useTranslation();

    return (
        <div className="px-16 md:px-48 pt-24 lg:pt-48 pb-24 z-0">
            <h1>{t("manual-introduction-title")}</h1>
            <p>{t("manual-introduction-text")}</p>
            <div className="flex flex-col text-base">
                <a href="/manual/prerequisites">{t("manual-prerequisites")}</a>
                <a href="/manual/basics">{t("manual-basics")}</a>
                <a href="/manual/drone-operations">
                    {t("manual-drone-operations")}
                </a>
                <a href="/manual/forest-management-planning">
                    {t("manual-forest-management-planning")}
                </a>
                <a href="/manual/action-planning">
                    {t("manual-action-planning")}
                </a>
                <a href="/manual/advanced">{t("manual-advanced")}</a>
            </div>
        </div>
    );
}
