import React from "react";
import { useTranslation } from "react-i18next";
import { LanguageSelection } from "../LanguageSelection/LanguageSelection";
import { NavigationLink } from "../NavigationLink/NavigationLink";
import { PageSelection } from "../PageSelection/PageSelection";

export function Navbar() {
    const { t } = useTranslation();

    return (
        <div className="flex bg-teal h-24 items-center fixed w-full z-50 md:px-36">
            <div className="items-center h-full flex relative justify-between w-full">
                <PageSelection />
                <div className="hidden xl:flex h-full">
                    <NavigationLink
                        title={t("home")}
                        target=""
                    />
                    <NavigationLink
                        title={t("manual")}
                        target="manual/introduction"
                    />
                    <NavigationLink
                        title={t("guides")}
                        target="guides"
                    />
                    <NavigationLink
                        title={t("faq")}
                        target="faq"
                    />
                    <NavigationLink
                        title={t("api")}
                        target="api"
                    />
                </div>
                <LanguageSelection />
            </div>
        </div>
    );
}
